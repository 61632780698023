import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  ContactMoment,
  Disease,
  DiseaseDataService,
  Dossier,
  DossierDataService,
  KeyValuePairs,
  Photo,
  Product,
  ProductDataService,
  ProductMatrixRow,
  Relation,
  RelationCertificate,
  DossierReminder,
  Unit,
  UserDataService,
  UserOption,
  PriceUnitIdentifier,
  Order,
  OrderDataService,
  Treatment,
  AuthService,
} from '@ppa/data';
import {
  ConfirmDialogComponent,
  HeaderData,
  Intention,
  MenuService,
  ModalService,
  PPAToastrService,
  StatusLabel,
} from '@ppa/layout';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pluck,
  publishReplay,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CertificateService } from '../../../../services/certificate.service';
import { ErrorHandlerService } from '@ppa/layout';
import { Address } from '../../../../services/location.service';
import { ScrollService } from '../../../../services/scroll-service';
import { UnitService } from '../../../../services/unit.service';
import { SelectLocationComponent } from '../../../location/modals/select-location/select-location.component';
import { AddPhotoModalComponent } from '../../../photo/modals/add-photo-modal/add-photo-modal.component';
import { RelationService } from '../../../relation/services/relation.service';
import { DossierService } from '../../services/dossier.service';
import { DossierTabbarPage } from '../../contracts/dossierTabbarPage';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../../../../../environments/environment';
import { FormIsRequiredComponent } from '../../../../components/form-is-required/form-is-required.component';
import { OrderSelectModalComponent } from '../../../order/modals/order-select-modal/order-select-modal.component';
import * as moment from 'moment/moment';

const NORMAL_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ppa-dossier-edit',
  templateUrl: './dossier-edit.component.html',
  styleUrls: ['./dossier-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: NORMAL_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
})
export class DossierEditComponent extends FormIsRequiredComponent implements OnInit, OnDestroy {
  activeModules = environment.activeModules;
  isCreateComponent = false;
  isContactMoment = false;

  destroy$ = new Subject();

  id = 0;
  dossierForm: FormGroup;
  contactMomentForm: FormGroup;
  orderId$: Observable<number>;
  orders$: Observable<Order[]>;
  status: StatusLabel;

  products$: Observable<Product[]>;
  mediators$: Observable<UserOption[]>;
  offMeadowDiseases$: Observable<Disease[]>;
  barnDiseases$: Observable<Disease[]>;
  selectedProduct$: Observable<Product>;
  priceUnitOptions$: Observable<Unit[]>;

  displaySurface$ = new BehaviorSubject<boolean>(false);
  displayExpectedAmount$ = new BehaviorSubject<boolean>(false);
  displayTonSuffix$ = new BehaviorSubject<boolean>(true);

  productSection$: Observable<boolean>;
  cultivationSection$: Observable<boolean>;
  saleSection$: Observable<boolean>;
  seedsSection$: Observable<boolean>;
  stageSection$: Observable<boolean>;
  qualitySection$: Observable<boolean>;
  tradeSection$: Observable<boolean>;
  othersSection$: Observable<boolean>;

  productConditionalFields: Map<string, string | null> = new Map<string, string | null>();
  stageOptions: KeyValuePairs<string>;
  preferenceDeliveryOptions: KeyValuePairs<string>;
  monthOptions: KeyValuePairs<number>;
  colorGrades: number[];
  tareGradeOptions: KeyValuePairs<string>;
  gritGradeOptions: KeyValuePairs<string>;
  qualityOptions: KeyValuePairs<string>;
  industryQualityOptions: KeyValuePairs<string>;
  sproutInhibitorOptions: KeyValuePairs<string>;
  sellTypeOptions: KeyValuePairs<string>;
  rentTypeOptions: KeyValuePairs<string>;
  storageBoxOptions: KeyValuePairs<string>;
  breedingSellingOptions: KeyValuePairs<string>;
  fungicideCoatingOptions: KeyValuePairs<boolean>;
  packagingOptions: KeyValuePairs<string>;
  amountUnitOptions$: Observable<Unit[]>;

  carouselPhotos$: BehaviorSubject<Photo[]> = new BehaviorSubject<Photo[]>(null);
  qualityIsIndustry$: Observable<boolean>;
  selectedStage$: Observable<string>;
  isOrganic$: Observable<boolean>;
  relationCertificates$: Observable<Partial<RelationCertificate>[]>;

  currentRelation$ = new BehaviorSubject<Relation>(null);
  currentAddress$: BehaviorSubject<Address> = new BehaviorSubject<Address>(null);

  openDossierCopy$ = new Subject<number>();

  subscriptions: Subscription[] = [];
  exporting$: Subject<boolean> = new Subject<boolean>();
  saving$: Subject<boolean> = new Subject<boolean>();

  expiresAtBeforeToday = false;

  _noRedirect = false;

  headerData: HeaderData;

  tabBarPage$ = new BehaviorSubject<DossierTabbarPage>(DossierTabbarPage.Main);

  dossierTabBarPage = DossierTabbarPage;

  sizeSorting$: Observable<string[]>;

  treatments$: Observable<Treatment[]>;

  @ViewChild('form') form: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dossierService: DossierService,
    private dossierDataService: DossierDataService,
    private orderDataService: OrderDataService,
    private productDataService: ProductDataService,
    private menuService: MenuService,
    private userDataService: UserDataService,
    private diseaseDataService: DiseaseDataService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
    private relationService: RelationService,
    private scrollService: ScrollService,
    private unitService: UnitService,
    private certificateService: CertificateService,
    private authService: AuthService,
  ) {
    super();
    this.id = this.route.snapshot.params.id;
    this.headerData = {
      left: {
        type: 'back',
        action: {
          icon: 'uil-arrow-left',
          routerLink: `/dossier/${this.id}`,
          label: 'modules.dossier.buttons.cancel',
          type: 'back-button',
        },
      },
      right: [
        {
          type: 'no-redirect-toggle',
          label: 'modules.dossier.create.no_redirect',
        },
        {
          icon: 'uil-check',
          color: 'primary',
          type: 'submit',
          label: 'modules.dossier.buttons.save',
        },
      ],
    };

    // Make sure the menu is visible before anything is checked
    this.menuService.setMenuVisible(false);
    this.exporting$.next(false);
    this.saving$.next(false);
  }

  get controls() {
    return this.dossierForm.controls;
  }

  get contactMoment() {
    return this.contactMomentForm;
  }

  get location(): [Address, [number, number]] {
    return this.dossierForm.get('location').value;
  }

  get certification() {
    return this.dossierForm.get('certification') as FormArray;
  }

  get contactMoments() {
    return this.dossierForm.get('contactMoments') as FormArray;
  }

  get reminders() {
    return this.dossierForm.get('reminders') as FormArray;
  }

  ngOnInit(): void {
    this.amountUnitOptions$ = this.unitService.getAmountUnits();
    this.stageOptions = this.dossierService.stageOptions;
    this.preferenceDeliveryOptions = this.dossierService.preferenceDeliveryOptions;
    this.monthOptions = this.dossierService.monthOptions;
    this.colorGrades = this.dossierService.colorGrades;
    this.tareGradeOptions = this.dossierService.tareGradeOptions;
    this.gritGradeOptions = this.dossierService.gritGradeOptions;
    this.qualityOptions = this.dossierService.qualityOptions;
    this.industryQualityOptions = this.dossierService.industryQualityOptions;
    this.sproutInhibitorOptions = this.dossierService.sproutInhibitorOptions;
    this.sellTypeOptions = this.dossierService.sellTypeOptions;
    this.rentTypeOptions = this.dossierService.rentTypeOptions;
    this.storageBoxOptions = this.dossierService.storageBoxOptions;
    this.breedingSellingOptions = this.dossierService.breedingSellingOptions;
    this.fungicideCoatingOptions = this.dossierService.fungicideCoatingOptions;
    this.packagingOptions = this.dossierService.packagingOptions;

    const contactRequired = this.isContactMoment ? [Validators.required] : [];

    this.dossierForm = this.formBuilder.group({
      relation: [null, [Validators.required]],
      location: [],
      locationNote: [null],
      mediator: [null, [Validators.required]],
      title: [null],
      expiresAt: [null],
      localPhotos: [[]],
      contactMoments: this.formBuilder.array([]),
      reminders: this.formBuilder.array([]),
      ...this.dossierService.dossierFormSectionFields['product'],
      ...this.dossierService.dossierFormSectionFields['cultivation'],
      ...this.dossierService.dossierFormSectionFields['sale'],
      ...this.dossierService.dossierFormSectionFields['seeds'],
      ...this.dossierService.dossierFormSectionFields['stage'],
      ...this.dossierService.dossierFormSectionFields['quality'],
      ...this.dossierService.dossierFormSectionFields['trade'],
      ...this.dossierService.dossierFormSectionFields['others'],
      certification: this.formBuilder.array([]),
      amountOfUnit: [{ value: null, disabled: false }, [Validators.pattern(/^\d+(([.,])\d{1,2})?$/)]],
      amountUnit: [{ value: null, disabled: false }],
      cmContactDate: [null, contactRequired],
      cmMediator: [null, contactRequired],
      cmDescription: [null],
    });

    if (this.isContactMoment) {
      this.headerData.right.shift();

      const now = moment(new Date());
      this.dossierForm.get('cmContactDate').patchValue(now);
      this.dossierForm.get('cmMediator').patchValue({ id: this.authService.getUserIdFromToken() });
    }

    this.productSection$ = this.dossierService.getSectionVisibility('product');
    this.cultivationSection$ = this.dossierService.getSectionVisibility('cultivation');
    this.saleSection$ = this.dossierService.getSectionVisibility('sale');
    this.seedsSection$ = this.dossierService.getSectionVisibility('seeds');
    this.stageSection$ = this.dossierService.getSectionVisibility('stage');
    this.qualitySection$ = this.dossierService.getSectionVisibility('quality');
    this.tradeSection$ = this.dossierService.getSectionVisibility('trade');
    this.othersSection$ = this.dossierService.getSectionVisibility('others');

    this.checkSectionVisibility();

    this.selectedProduct$ = this.controls.product.valueChanges.pipe(
      tap(this.onProductChange.bind(this)),
      publishReplay(1),
    );

    this.selectedProduct$.subscribe((product) => {
      this.stageOptions = this.dossierService.stageOptionsForProduct(product, this.dossierForm.get('stage'));
    });
    this.subscriptions.push((this.selectedProduct$ as ConnectableObservable<Product>).connect());

    this.selectedStage$ = this.controls.stage.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((stage) => {
        this.productConditionalFields.forEach((propertyStage, property) => {
          if (!propertyStage) return;

          const field = this.dossierForm.get(property);
          if (propertyStage.indexOf(stage) > -1) {
            field.enable();
          } else {
            field.updateValueAndValidity();
            field.disable();
          }
        });

        this.checkSectionVisibility();
      }),
      publishReplay(1),
    );
    this.subscriptions.push((this.selectedStage$ as ConnectableObservable<string>).connect());

    this.offMeadowDiseases$ = this.diseaseDataService.listOffMeadowDiseases();
    this.barnDiseases$ = this.diseaseDataService.listBarnDiseases();
    this.products$ = this.productDataService.getProductOptions();
    this.mediators$ = this.userDataService.options().pipe(share());
    this.priceUnitOptions$ = this.unitService.getPriceUnits();

    const { id } = this.activatedRoute.snapshot.params;

    if (this.isCreateComponent) {
      this.orderDataService.read(id).subscribe((data: Order) => {
        this.orderId$ = of(data.id);
        this.id = null;
        data.id = null;
        data.title = null;
        this.dossierForm.patchValue(data);
        data.relation.addressLine = `${data.relation.street} ${data.relation.houseNumber}, ${data.relation.city}`;
        this.currentRelation$.next(data.relation);
        this.relationCertificates$ = this.fetchSelectableCertificatesByOrder(data, this.currentRelation$);

        this.currentAddress$.next(null);
      });
    } else {
      this.id = id;
      if (id) {
        this.loadDossier(id);

        this.openDossierCopy$.pipe(takeUntil(this.destroy$)).subscribe((dossierId) => {
          // Small workaround to make the back button go to /dossier en make the menu work properly
          this.router.navigate(['/dossier']).then(() => {
            this.router.navigate(['/dossier', 'edit', dossierId]);
          });
        });
      }
    }

    this.controls.breedingSelling.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!value) {
        return;
      } else if (value === 'breeding') {
        this.controls.surfaceCultivation.enable();
        this.controls.sowingDate.enable();
        this.controls.plantsPerHa.enable();
        this.controls.expectedAmountSale.disable();
        this.controls.sizeSorting.disable();
        this.controls.treatment.disable();
      } else {
        this.controls.surfaceCultivation.disable();
        this.controls.sowingDate.disable();
        this.controls.plantsPerHa.disable();
        this.controls.expectedAmountSale.enable();
        this.controls.sizeSorting.enable();
        this.controls.treatment.enable();
      }

      this.displaySurface$.next(value === 'breeding');
      this.displayExpectedAmount$.next(value !== 'breeding');

      this.checkSectionVisibility();
    });

    this.isOrganic$ = this.dossierForm.get('organic').valueChanges.pipe(takeUntil(this.destroy$), startWith(false));

    this.qualityIsIndustry$ = this.controls.quality.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(this.onQualityChange.bind(this)),
      switchMap((quality) => of(quality === 'industry')),
      publishReplay(1),
    );
    this.subscriptions.push((this.qualityIsIndustry$ as ConnectableObservable<boolean>).connect());

    this.dossierForm
      .get('expiresAt')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.expiresAtBeforeToday = this.dossierService.checkDateTimeBeforeNow(value)));

    // verplichte validatie van amountUnit wanneer amountOfUnit een waarde heeft
    this.dossierForm.get('amountOfUnit').valueChanges.subscribe((value) => {
      const amountUnitField = this.controls.amountUnit;
      amountUnitField.setValidators(value ? [Validators.required] : []);
      amountUnitField.updateValueAndValidity({ emitEvent: false });
    });

    this.setupUnitFields();
    this.setupSurfaceField();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  loadDossier(id) {
    this.dossierDataService.read(id).subscribe(
      (data: Dossier) => {
        if (data.status === 'closed') {
          this.status = StatusLabel.Closed;
        } else if (data.status === 'incomplete') {
          this.status = StatusLabel.Incomplete;
        }
        this.orderId$ = of(data.order ? data.order.id : -1);
        this.orders$ = of(data.orders);
        this.dossierForm.patchValue(data);
        this.currentRelation$.next(data.relation);
        this.relationCertificates$ = this.fetchSelectableCertificates(data, this.currentRelation$);

        if (data.location) {
          const [address] = data.location ?? null;
          this.currentAddress$.next(address ? address : null);
        } else {
          this.currentAddress$.next(null);
        }

        setTimeout(() => {
          this.carouselPhotos$.next([...data.photos]);
        }, 500);
        this.cdr.detectChanges();
      },
      (error: HttpErrorResponse) => {
        this.errorHandler.handleHTTPErrorResponse({
          error,
          resource: 'modules.dossier.toastr.resource_name',
          navigation: ['/dossier'],
        });
      },
    );
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  private fetchSelectableCertificates(dossier: Dossier, currentRelation$: BehaviorSubject<Relation>) {
    return combineLatest([
      currentRelation$.pipe(pluck('certificates')),
      this.certificateService.getCertificateOptions(),
    ]).pipe(
      map((set) => {
        const [relationCertificates, certificateOptions] = set;
        const { certification: dossierCertificates } = dossier;
        return this.certificateService.combineCertificateSources(
          relationCertificates,
          certificateOptions,
          dossierCertificates,
        );
      }),
      tap((relationCertificates) => {
        this.addCertificateFormControls(relationCertificates);
      }),
    );
  }

  private fetchSelectableCertificatesByOrder(order: Order, currentRelation$: BehaviorSubject<Relation>) {
    return combineLatest([
      currentRelation$.pipe(pluck('certificates')),
      this.certificateService.getCertificateOptions(),
    ]).pipe(
      map((set) => {
        const [relationCertificates, certificateOptions] = set;
        const { certification: dossierCertificates } = order;
        return this.certificateService.combineCertificateSources(
          relationCertificates,
          certificateOptions,
          dossierCertificates,
        );
      }),
      tap((relationCertificates) => {
        this.addCertificateFormControls(relationCertificates);
      }),
    );
  }

  private addCertificateFormControls(certificates: Partial<RelationCertificate>[]): void {
    this.certification.clear();
    certificates.map((certificate) => {
      this.certification.push(this.formBuilder.group(certificate));
    });
  }

  onQualityChange(quality: string) {
    const industryQualityField = this.controls.industryQuality;
    const industrial = quality === 'industry';
    if (industrial) {
      industryQualityField.enable();
    } else {
      industryQualityField.updateValueAndValidity();
      industryQualityField.disable({ emitEvent: true });
    }
    this.checkSectionVisibility();
  }

  get localPhotos(): Photo[] {
    return this.dossierForm.get('localPhotos').value;
  }

  onProductChange(product: Product) {
    this.setConditionalFields(product);
    this.controls.stage.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    this.controls.quality.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    this.controls.mediator.updateValueAndValidity({ onlySelf: true, emitEvent: true });

    if (this.controls.breedingSelling.status !== 'DISABLED') {
      this.controls.breedingSelling.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    this.checkSectionVisibility();
  }

  openRelationModal() {
    const relationControl = this.controls.relation;
    relationControl.markAsTouched();

    this.relationService
      .selectRelation()
      .pipe(take(1))
      .subscribe((relation) => {
        if (typeof relation === 'object') {
          this.currentRelation$.next(relation as Relation);
          relationControl.patchValue(relation);
        }
      });
  }

  openLocationModal() {
    const [address, marker] = this.location || [null, null];
    const relationLocation$ = this.currentRelation$.pipe(
      map((relation) => {
        return [relation.longitude, relation.latitude];
      }),
    );

    const props = {
      address: address !== null ? address : null,
      marker: marker !== null ? marker : null,
      relationLocation$,
      useCurrentLocationOnLoad: false,
    };

    const modal = this.modalService.createModal(SelectLocationComponent, props);

    modal.dialog.onSave().then((data: [Address, [number, number]]) => {
      this.dossierForm.get('location').setValue(data);

      const [newAddress] = data;
      this.currentAddress$.next(newAddress);
    });
  }

  openPhotoModal() {
    const localPhotosControl = this.dossierForm.get('localPhotos');
    localPhotosControl.markAsTouched();

    const modal = this.modalService.createModal(AddPhotoModalComponent, {
      localPhotos: this.localPhotos.length > 0 ? this.localPhotos : [],
    });
    modal.dialog.onSave().then((data) => {
      if (data.length > 0) {
        data.sort(this.dossierDataService.sortPhotos);
      }
      localPhotosControl.setValue(data);
      localPhotosControl.markAsDirty();
      this.carouselPhotos$.next([...data]);
      this.cdr.detectChanges();
    });
  }

  clearDisabledInvisibleFieldValues() {
    const keys = Object.keys(this.controls);
    for (const key of keys) {
      const control = this.controls[key];
      const isConditional = this.productConditionalFields.has(key);
      if (isConditional && control.disabled) {
        control.patchValue(null);
        control.enable();
      }
    }
  }

  handleSubmit() {
    if (!this.dossierForm.valid) {
      this.scrollService.scrollToFirstInvalid(this.dossierForm);
      return;
    }

    this.saving$.next(true);

    this.clearDisabledInvisibleFieldValues();

    if (this.id > 0) {
      // Update dossier
      const dossier = {
        ...{ id: this.id },
        ...this.dossierForm.value,
        certification: this.certificateService.transformCertificationInput(this.certification.value),
      };
      this.dossierService.updateDossier(dossier).subscribe(
        () => {
          this.saving$.next(false);

          if (!this._noRedirect) {
            this.form.reset();
            this.router.navigate(['/dossier', this.id]);
          } else {
            this.dossierForm.markAsPristine();
            this.dossierForm.markAsUntouched();

            this.loadDossier(this.id);
          }

          const message = this.isContactMoment
            ? translate('modules.dossier.toastr.contact_moment_added')
            : translate('modules.dossier.toastr.dossier_updated');

          this.toastrService.displayToastr({
            icon: 'uil-check',
            title: message,
            intention: Intention.Success,
            duration: 3000,
          });
        },
        () => {
          const message = this.isContactMoment
            ? translate('modules.dossier.toastr.contact_moment_error')
            : translate('modules.dossier.toastr.dossier_error');

          this.saving$.next(false);
          this.toastrService.displayToastr({
            icon: 'uil-exclamation-triangle',
            title: translate('modules.dossier.toastr.dossier_error'),
            intention: Intention.Error,
            duration: 3000,
          });
        },
      );
    } else {
      let orderId = 0;
      const dossier = {
        ...this.dossierForm.value,
        certification: this.certificateService.transformCertificationInput(this.certification.value),
      };
      this.orderId$.subscribe((id) => (orderId = id));
      this.dossierService.saveDossier(dossier).subscribe(
        (savedDossier: Dossier) => {
          this.orderDataService.addDossierToOrder(orderId, savedDossier.id);
          this.saving$.next(false);

          if (!this._noRedirect) {
            // this.form.reset();
            this.router.navigate(['/dossier']);
          } else {
            this.dossierForm.markAsPristine();
            this.dossierForm.markAsUntouched();
          }

          const onButtonClick$ = new Subject<void>();
          onButtonClick$.subscribe(() => this.router.navigate(['/dossier', savedDossier.id]));

          this.toastrService.displayToastr({
            icon: 'uil-check',
            title: translate('modules.dossier.toastr.dossier_added'),
            intention: Intention.Success,
            buttons: [
              {
                text: translate('modules.dossier.toastr.view_dossier'),
                sub: onButtonClick$,
              },
            ],
          });
        },
        () => {
          this.saving$.next(false);
          this.toastrService.displayToastr({
            icon: 'uil-exclamation-triangle',
            title: translate('modules.dossier.toastr.dossier_error'),
            intention: Intention.Error,
            duration: 3000,
          });
        },
      );
    }
  }

  private setConditionalFields(selectedProduct: Product) {
    this.productConditionalFields.forEach((stage: string | null, fieldName: string) => {
      const field = this.dossierForm.get(fieldName);
      if (field) {
        field.updateValueAndValidity();
        field.disable();
      }
    });
    this.productConditionalFields = new Map<string, string | null>();

    if (!selectedProduct) {
      return;
    }

    selectedProduct.productMatrix?.forEach((productMatrixRow: ProductMatrixRow) => {
      const field = this.dossierForm.get(productMatrixRow.property);
      if (!field) {
        return;
      }

      if (!productMatrixRow.stage) {
        field.enable();
      }
      this.productConditionalFields.set(productMatrixRow.property, productMatrixRow.stage);
    });

    this.dossierService.setDefaultValues(this.dossierForm, selectedProduct.defaultValues);

    this.sizeSorting$ = this.dossierService.setSizeSortingOptions(selectedProduct.defaultValues);

    this.treatments$ = this.dossierService.setTreatmentOptions(selectedProduct.defaultValues);
  }

  private checkSectionVisibility() {
    this.dossierService.checkSectionVisibility(this.dossierForm, [
      'expectedHarvestExactDate',
      'productNote',
      'cultivationNote',
      'saleNote',
      'seedsNote',
      'stageNote',
      'qualityNote',
      'tradeNote',
      'othersNote',
    ]);
  }

  private setupUnitFields() {
    // when this is an edit form skip the first emits from the amountOfUnit and amountUnit
    combineLatest([
      this.dossierForm.get('amountOfUnit').valueChanges.pipe(filter(Boolean), debounceTime(1000)) as Observable<string>,
      this.dossierForm.get('amountUnit').valueChanges.pipe(filter(Boolean)) as Observable<Unit>,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([amount, unit]) => {
        if (!amount || !unit) {
          return;
        }
        const amountFields = [
          this.dossierForm.get('expectedAmountSale'),
          this.dossierForm.get('expectedAmountSeed'),
          this.dossierForm.get('expectedAmount'),
          this.dossierForm.get('estimatedAmount'),
        ];
        // only one of these fields is enabled
        const enabledField = amountFields.find((f) => f.enabled);
        if (
          enabledField &&
          (enabledField.value == null ||
            enabledField.value === '' ||
            enabledField.value === 'NaN' ||
            isNaN(enabledField.value))
        ) {
          const forceAmount = this.dossierService.getDefaultValue(
            this.dossierForm.get('product').value.defaultValues,
            'forceAmount',
          );
          if (unit.identifier === PriceUnitIdentifier.Price_unit_per_stuk || forceAmount) {
            enabledField.setValue(amount);
            this.displayTonSuffix$.next(false);
          } else if (unit.divisor === null) {
            enabledField.setValue(null);
            this.displayTonSuffix$.next(true);
          } else {
            const convertedAmount = parseFloat(amount.toString().replace(',', '.'));
            enabledField.setValue(((convertedAmount * unit.divisor) / 1000).toFixed(2));
            this.displayTonSuffix$.next(true);
          }
        }
      });
  }

  public calculateAmount(forceAmount: boolean = false) {
    const amountFields = [
      this.dossierForm.get('expectedAmountSale'),
      this.dossierForm.get('expectedAmountSeed'),
      this.dossierForm.get('expectedAmount'),
      this.dossierForm.get('estimatedAmount'),
    ];
    const enabledField = amountFields.find((f) => f.enabled);
    if (
      enabledField &&
      (enabledField.value == null ||
        enabledField.value === '' ||
        enabledField.value === 'NaN' ||
        isNaN(enabledField.value) ||
        forceAmount)
    ) {
      if (forceAmount) {
        enabledField.patchValue('');
      }
      this.dossierForm.get('amountOfUnit').updateValueAndValidity();
    }
  }

  private setupSurfaceField() {
    this.controls.surface.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe((value) => {
      if (this.controls.stage.value === 'off_meadow' && value !== null && value !== '') {
        if (typeof this.controls.amountOfUnit !== undefined) {
          const oldValue = this.controls.amountOfUnit.value;
          const oldUnit = this.controls.amountUnit.value;
          if (
            ((oldValue === '' || oldValue == null) && (oldUnit === '' || oldUnit == null)) ||
            oldUnit?.identifier === PriceUnitIdentifier.Price_unit_per_1_ha
          ) {
            this.controls.amountOfUnit.reset();
            this.controls.amountOfUnit.patchValue(value);

            this.amountUnitOptions$.subscribe((units) => {
              const unit = units.find(
                (amountUnit) => amountUnit.identifier === PriceUnitIdentifier.Price_unit_per_1_ha,
              );
              this.controls.amountUnit.patchValue(unit);
            });
          }
        }
      }
    });
  }

  /**
   * Handle order action card click event.
   */
  orderLinkAction() {
    this.orders$.subscribe((orders) => {
      const { sellType } = this.dossierForm.value;

      if (orders.length === 0) {
        this.router.navigate(['/order', 'create'], {
          queryParams: { dossier: this.id, ...(sellType && { sellType }) },
        });
      } else {
        const modal = this.modalService.createModal(OrderSelectModalComponent, {
          orders,
          dossier: this.id,
          ...(sellType && { sellType }),
        });
        modal.dialog.onSave().then((orderId) => {
          this.router.navigate(['/order', orderId]);
        });
      }
    });
  }

  isRequiredField(field: string, controls?: any): Observable<boolean> {
    try {
      if (!controls) {
        controls = this.controls;
      } else if (controls.controls && controls.controls.length > 0) {
        controls = controls.controls[0].controls;
      }
      const control = controls[field];
      if (control) {
        // @ts-ignore
        const validators = control.validator('');
        if (validators.required) {
          return of(true);
        }
      }
    } catch (e) {
      return of(false);
    }

    return of(false);
  }

  handleToggleNoRedirect($event: boolean) {
    this._noRedirect = $event;
  }
}
