import { AuthService, ErrorResponse, TokenResponse } from '@ppa/data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Intention } from '../../contracts';
import { PPAToastrService } from '../../services';

@Component({
  selector: 'ppa-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  passwordForm: FormGroup;
  formType: string;

  @Input() dev = false;
  @Output() loginSuccessful = new EventEmitter<TokenResponse>();
  @Output() loginFailed = new EventEmitter<ErrorResponse>();

  constructor(private fb: FormBuilder, private authService: AuthService, private toastrService: PPAToastrService) {}

  ngOnInit(): void {
    this.formType = 'login';

    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });

    if (this.dev) {
      this.loginForm.get('email').patchValue('dev@webnl.nl');
      this.loginForm.get('password').patchValue(new Date().toISOString());
    }

    this.passwordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  login() {
    /* Disable the form so it cannot be changed while backend is validating */
    this.loginForm.disable();

    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;

    this.authService.login(email, password).subscribe(
      (response) => {
        this.loginForm.enable();
        this.loginSuccessful.emit(response);
      },
      (errorResponse) => {
        this.loginForm.enable();
        this.loginFailed.emit(errorResponse.error);
      },
    );
  }

  forgot() {
    this.passwordForm.disable();

    const email = this.passwordForm.get('email').value;
    const website = location.origin;

    this.authService.loginLink(email, website).subscribe(
      (response) => {
        this.passwordForm.enable();
        this.displayMessage();
        this.switchForm();
      },
      (errorResponse) => {
        this.passwordForm.enable();
        this.displayMessage();
        this.switchForm();
      },
    );
  }

  displayMessage() {
    this.toastrService.displayToastr({
      icon: 'uil-check',
      title: translate('modules.auth.login.password_send'),
      intention: Intention.Success,
      fullWidth: true,
      duration: 3000,
    });
  }

  switchForm() {
    if (this.formType === 'login') {
      this.formType = 'password';
    } else {
      this.formType = 'login';
    }
  }
}
