<div class="container container--flex-direction-column">
  <div class="heading">
    <h1 class="subheading-enabled">{{ 'search.title' | transloco }}</h1>
    <h4 class="subheading">{{ 'search.subtitle' | transloco }}</h4>
  </div>

  <div>
    <ppa-action-card
      actionIcon="uil-search"
      (actionClicked)="openRelationModal()"
      (contentClicked)="openRelationModal()"
      [addMarginBottom]="true"
    >
      <ng-container *ngIf="currentRelation$ | async as currentRelation; else relationPlaceholder">
        <h3 class="relation__company-name">{{ currentRelation.companyName }}</h3>
      </ng-container>
      <ng-template #relationPlaceholder>
        <div class="relation-placeholder">{{ 'search.select_relation' | transloco }}</div>
      </ng-template>
    </ppa-action-card>
  </div>

  <ng-container *ngIf="currentRelation$ | async as currentRelation">
    <h2>{{ 'search.relation_title' | transloco }}</h2>
    <ppa-action-card
      [actionIcon]="phoneIcon(currentRelation.phoneNumber)"
      class="mb-2"
      (contentClicked)="callNumber(currentRelation.phoneNumber)"
      (actionClicked)="callNumber(currentRelation.phoneNumber)"
    >
      {{ currentRelation.companyName }}
      <span class="action-card__content--secondary">{{ currentRelation.phoneNumber }}</span>
    </ppa-action-card>
    <ppa-action-card
      actionIcon="uil-angle-right"
      class="mb-2"
      (contentClicked)="openLocation(currentRelation)"
      (actionClicked)="openLocation(currentRelation)"
    >
      {{ currentRelation.street }} {{ currentRelation.houseNumber }}, <br />
      {{ currentRelation.zipcode }} {{ currentRelation.city }}
    </ppa-action-card>
    <ppa-action-card
      actionIcon="uil-pen"
      (contentClicked)="editRelation(currentRelation)"
      (actionClicked)="editRelation(currentRelation)"
      [addMarginBottom]="true"
    >
      {{ 'search.edit_relation' | transloco }}
    </ppa-action-card>

    <ng-container *ngIf="currentRelation.certificates as certificates">
      <ng-container *ngIf="certificates.length > 0">
        <h2 class="clickable" (click)="toggleCertificates()">
          {{ 'modules.dossier.detail.section.certificates' | transloco }} ({{ certificates.length }})
          <i class="uil" [class.uil-angle-down]="!showCertificates" [class.uil-angle-up]="showCertificates"></i>
        </h2>
        <ng-container *ngIf="showCertificates">
          <ng-container *ngFor="let relationCertificate of certificates">
            <ppa-certificate-checkbox
              [id]="relationCertificate.certificate?.id"
              [title]="relationCertificate.certificate.name"
              [expirationDate]="relationCertificate?.date"
              [organic]="relationCertificate.certificate?.organic"
              [certificate]="relationCertificate"
              [checked]="true"
              [hideCheckbox]="true"
            ></ppa-certificate-checkbox>
          </ng-container>
        </ng-container>
        <div class="action-cards--mb"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="currentRelation.contacts.length > 0">
      <h2 class="clickable" (click)="toggleContacts()">
        {{ 'search.contacts_title' | transloco }} ({{ currentRelation.contacts.length }})
        <i class="uil" [class.uil-angle-down]="!showContacts" [class.uil-angle-up]="showContacts"></i>
      </h2>

      <ng-container *ngIf="showContacts">
        <ppa-action-card
          *ngFor="let contact of currentRelation.contacts"
          [actionIcon]="phoneIcon(contact.phone)"
          class="mb-2"
          (contentClicked)="callNumber(contact.phone)"
          (actionClicked)="callNumber(contact.phone)"
        >
          {{ contact.firstname }} {{ contact.lastname }}
          <span class="action-card__content--secondary">{{ contact.phone }}</span>
        </ppa-action-card>
      </ng-container>

      <div class="action-cards--mb"></div>
    </ng-container>

    <h2>{{ 'search.result_title' | transloco }}</h2>

    <ng-container *ngIf="currentRelation$ | async as currentRelation">
      <ng-container *ngIf="currentRelation.summary as relationSummary">
        <ng-container *ngIf="activeModules.dossier">
          <ppa-action-card
            actionIcon="uil-angle-right"
            class="mb-2"
            (contentClicked)="openOverview(currentRelation, 'dossier')"
            (actionClicked)="openOverview(currentRelation, 'dossier')"
          >
            {{ 'search.dossier_label' | transloco: { count: relationSummary.dossierCount } }}
          </ppa-action-card>
        </ng-container>

        <ng-container *ngIf="activeModules.order">
          <ppa-action-card
            actionIcon="uil-angle-right"
            class="mb-2"
            (contentClicked)="openOverview(currentRelation, 'order')"
            (actionClicked)="openOverview(currentRelation, 'order')"
          >
            {{ 'search.order_label' | transloco: { count: relationSummary.orderCount } }}
          </ppa-action-card>
        </ng-container>

        <ng-container *ngIf="activeModules.contract">
          <ppa-action-card
            actionIcon="uil-angle-right"
            class="mb-2"
            (contentClicked)="openOverview(currentRelation, 'contract')"
            (actionClicked)="openOverview(currentRelation, 'contract')"
          >
            {{ 'search.contract_label' | transloco: { count: relationSummary.contractCount } }}
          </ppa-action-card>
        </ng-container>

        <ng-container *ngIf="activeModules.delivery">
          <ppa-action-card
            actionIcon="uil-angle-right"
            class="mb-2"
            (contentClicked)="openOverview(currentRelation, 'delivery')"
            (actionClicked)="openOverview(currentRelation, 'delivery')"
          >
            {{ 'search.delivery_label' | transloco: { count: relationSummary.deliveryCount } }}
          </ppa-action-card>
        </ng-container>

        <ng-container *ngIf="activeModules.invoice">
          <ppa-action-card
            *ngIf="!authService.isMediator()"
            actionIcon="uil-angle-right"
            (contentClicked)="openOverview(currentRelation, 'administration/invoice')"
            (actionClicked)="openOverview(currentRelation, 'administration/invoice')"
          >
            {{ 'search.invoice_label' | transloco: { count: relationSummary.invoiceCount } }}
          </ppa-action-card>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
